body *{
	font-family: sans-serif;
}

.products_container{
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 100%;
    /*background-color: red;*/
    justify-items:center;
    align-items: center;

    .single_product{
		width: 280px;
		display: grid;
		// justify-self:center;
		grid-template-rows: auto 50px max-content max-content 41px;
		/*align-items: center;*/
		height: 400px;
		background-color: #eee;
		/*border: 1px solid black;*/
		// padding: 12px;
		// box-sizing:border-box;
		margin: 20px;
		cursor: pointer;
		font-family: "Lato",sans-serif;
		background-color: white;
		box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
		// border-radius: .375rem;

		.product_image_container{
			position: relative;
			background-color: transparent;
			height: 100%;
			overflow: hidden;
			.product_image_div{
				position: relative;
				width:100%;
				height: 100%;
				// background: url("pipe.png");
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				overflow: hidden;
				background-color: white;
			}
			.image_ghost{
				position: absolute;
				left:0%;
				top: 0%;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0,0.5);
			}
		}
		.product_name{
			// align-self:center;
			padding: 5px;
			overflow: hidden;
			font-size: 12px; 
			font-weight: 600;
			display: -webkit-box;
		  	-webkit-line-clamp: 3;
		  	-webkit-box-orient: vertical;  
		  	overflow: hidden;
		  	line-height: 1.2!important;
		  	color: grey;
		}

		.product_price{
			font-size: 18px;
			font-weight: 600;
			color:#0d6efd;
			font-family: "Arial",sans-serif;

			.product_unit{
				font-size: 9px;
				// font-weight:500; 
				color: grey;
			}
		}

		.product_brand{
			color: #555;
			font-size: 10px;
			padding: 4px 0px;
			box-sizing:border-box;
			// box-sizing:border-box;
		}

		.view_button_container{
			padding-bottom: 10px;
			box-sizing:border-box;
			.view_button{
				display: flex;
				align-items:center;
				justify-content:center;
				height: 100%;
				// box-sizing:border-box;
				border-radius: 3px;
				color: white;
				font-size: 13px;
				// background-color: black;
				background-image: linear-gradient(to bottom right, #01806e, green);
			}
		}

		> *{
			text-align: left;
		}
		>:not(:first-child){
			padding-left:12px;
			padding-right:12px;
			box-sizing:border-box; 
		}

	}

	@media only screen and (max-width: 1400px) {
 		// grid-template-columns: auto auto auto auto;
 		.single_product{
 			height: 300px;
 			width: 240px;
 			.product_image_div{
 				// height: 150px;
 			}
 		} 	
	}
	@media only screen and (max-width: 1200px) {
 		grid-template-columns: auto auto auto auto;
 		.single_product{
	 		height: 280px;
	 		width : 210px;
	 	}
 	}
 	
	@media only screen and (max-width: 1000px) {
 		grid-template-columns: auto auto auto;
	 	.single_product{
	 		height: 260px;
	 		width : 210px;
	 	}
 	}
	@media only screen and (max-width: 800px) {
	 	grid-template-columns: auto auto auto;
	 	.single_product{
	 		height: 220px;
	 		width : 160px;
		 	.product_image_div{
	 				// height: 140px;
			}
			.products_title {
				font-size:10px;
			}

			.products_price {
				font-size:16px;
			}

			.product_name{
				font-size: 12px;
				padding-top: 4px;
				padding-bottom: 2px;
			}

			.product_brand{
				font-size: 10px;
			}

			// .product_price{
			// 	font-size: 12px;
			// }
		}

	}
	@media only screen and (max-width: 600px) {
		grid-template-columns: 50% 50%;
 		.single_product{
	 		height: 244px;
	 		width: 91%;
	 		grid-template-rows: 50% 35px max-content max-content 37px;
	 		margin: 10px;
	 		.product_image_div{
 				// height: 110px;
 			}

 			.product_name{
	 			height: auto;
		  		-webkit-line-clamp: 2;
		  		overflow: hidden;


 			}

 			.product_brand{
 				font-size: 10px;
 			}

 			.product_price{
				font-size: 16px;

				.product_unit{
					font-size: 10px;
				}
			}

			.view_button_container{
				.view_button{
	 				font-size: 12px;
					padding: 5px 0px;

	 			}
			}
 			
 		}
	}
		

}
