.view_product_block{
	position: relative;
	display: grid;
	grid-template-columns: 45% 55%;
	width: 100%;
	padding: 10px;
	box-sizing:border-box;
	// background-color: white;

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                          supported by Chrome, Edge, Opera and Firefox */
  }

	.image_block{
		position: relative;
		width:100%;
		padding: 10px;
		box-sizing:border-box;
		// overflow: hidden;

		.image_container{
			position: relative;
			display: grid;
			grid-template-rows:auto max-content;
			min-height: 400px;
			width:100%;
			padding: 10px;
			box-sizing:border-box;
			background-color: white;
			box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
			border-radius: .375rem;

			.product_image{
				position: relative;
				width:100%;
				height: 100%;
				// background-color: red;
				background-position:center;
				background-size: contain;
				background-repeat: no-repeat;
			}

			.other_images_container{
				position: relative;
				text-align: center;
				width:100%;
				height: 100%;
				// border:1px solid grey;
				// background-color: black;

				.images{
					position: relative;
					display: inline-block;
					width:100px;
					height: 100px;
					margin: 5px;
					// background-color: blue;
					background-position:center;
					background-size: contain;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}
	}

	.details_block{
		position: relative;
		width:100%;
		padding: 10px;
		box-sizing:border-box;
		// overflow: hidden;

		.details_container{
			position: relative;
			padding: 20px;
			min-height: 400px;
			box-sizing:border-box;
			background-color: white;
			box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
			border-radius: .375rem;

			.product_name{
				font-size: 18px;
				color: #333;
			}

			.product_brand{
				font-size: 14px;
				color: grey;
			}


			.product_price_container{
				font-size: 24px;
				color: #333;
				font-weight: 600;
				
				.product_unit{
				font-size: 12px;
				color: #555;
				}
			}

			.product_rating{
				color: orangered;
			} 

			.description_title{
				font-size : 12px;
                color : black;
                font-weight: 500;

			}

			.variants_container {
        margin-bottom: 7px;
				.variant_name{
          color: #a21ca2;
					font-weight: bold;
				}
				div{
					box-sizing:unset!important;
					color: #333;
					font-size: 12px;
				}
				.variation_values_container{
			      // padding: 0px 10px 5px 10px;    
			      .single_variation_value_container{
				      position: relative;
				      padding: 5px 9px;
				      margin-right: 10px;
				      cursor: pointer; 
				      display: inline-block;
				      color: #333;
              // font-size:14px;
				      font-weight: 600;
              @extend .noselect;
				    }

			    }

			    .unselected_variation_value{
			      border:2px solid lightgrey;
			    }

			    .selected_variation_value{
			      border: 2px solid #007bff;
			    }

			    
			}

			.product_description{
				font-size: 10px;
				font-family: "Lato",sans-serif;
                color : #333;

			}

			.addons_container{
				position: relative;
				display: grid;
				grid-column-gap:20px;
				grid-template-columns:max-content max-content;
				grid-row-gap :5px;


				.addons_title{
					display: inline-block;
					font-weight: 600;
					color: #3dbcf6;
					font-size: 15px;
				}
				.single_addon_container{
					display: grid;
					grid-template-columns:auto max-content;
					grid-column-gap:5px;
					align-items:center;
					label{
						// display: inline-block;
						font-size: 12px;
					}
				}

				@media(max-width: 1080px){
					grid-template-columns:max-content;
				}
			}

			.qty_container{
				position: relative;
				display: inline-block;
				align-self: start;
				border:1px solid lightgrey;
				border-radius: 5px;


				button{
					float: left;
					width:35px;
					height: 35px;
					outline: none;
					border:none;
					color: grey;
					font-weight:500;
					background-color: transparent;
				}

				.product_qty{
					float: left;
					width:45px;
					height: 35px;
					border-width: 0px 1px 0px 1px;
					border-color: lightgrey;
					border-style: solid;
					// outline: none;
					text-align: center;
					outline: none;
				}

			}

			.add_to_cart_button{
				position: relative;
				display: block;
				padding: 10px 25px;
				box-sizing:border-box;
				background-color: green;
				border: 1px solid green;
				border-radius: 7px;
				color: white; 

				&:hover{
					background-color: white;
					color: green;
				}
			}

			.addCartButton{
				position: fixed;
				z-index: 2022;
				display: none;
				justify-content:center;
				align-content:center;
				top:92%;
				left: 96%;
				color: white;
				font-weight: 600;
				font-size: 20px;
				background-color: green;
				border-radius: 7px;
			}

			>*{
				margin-bottom: 5px;
			}		
		}
	}
	@media(max-width: 1200px){
		.details_block{
			.details_container{
				.addons_container{	
					.addons_title{
						font-size: 12px;
					}
					.single_addon_container{ 
						label{
							font-size: 10px;
						}
					}
				}

			}
		}
	}

  .ghost{
    position: absolute;
    display: none;
    top:0%;
    left: 0%;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
  }
	@media(max-width: 900px){
		grid-template-columns:100%;
		height: auto;

		.image_block{
			.image_container{
				height: 300px;
				.other_images_container{
					.images{
						height: 70px;
						width: 70px;
					}
				}
			}
		}

		.details_block{
			padding: 5px;
			.details_container{
				.addons_container{
					grid-template-columns:max-content;	

					.addons_title{
						font-size: 14px;
					}
					.single_addon_container{

						label{
							font-size: 12px;
						}
					}
				}

			}
		}
	}


	@media(max-width: 480px){
		padding: 5px;

		.image_block{
			padding: 5px;
			.image_container{
				height: 250px;
			}		
		}

	}

}