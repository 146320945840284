.cart_block{
	position: relative;
	display: grid;
	grid-template-columns:auto 354px;
	width: 100%;
	padding: 10px;
	box-sizing:border-box;

	.cart_products_block{
		position: relative;
		width:100%;
		padding: 10px;
		box-sizing:border-box;
		// overflow: hidden;
		// background-color: blue;

		.cart_products_container{
			position: relative;
			width:100%;
			padding: 10px;
			box-sizing:border-box;
			background-color: white;
			box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
			border-radius: .375rem;


			.cart_title{
				position: relative;
				// font-size: 16px;
				color: #4f4f4f;
				font-family: Roboto,sans-serif;
			}

			.single_product{
				position: relative;
				display:grid;
				grid-template-columns:30% 40% 30%;
				min-height: 170px;
				height: auto;
				// background-color:white;
				padding: 5px;
				box-sizing:border-box;
				border-bottom: 1px solid lightgrey;
				overflow: hidden;

				.product_image{
					position: relative;
					// width:100%;
					padding: 10px;
					box-sizing:border-box;
					background-position: center;
					background-repeat: no-repeat;
					background-size: contain;
					// background-color: green;
				}

				.product_details{
					position: relative;
					display: grid;
					width:100%;
					padding: 5px;
					box-sizing:border-box;
					color: grey;

					// background-color: red;

					.product_name{
						color: #444;
						font-weight: 500;
						font-size: 16px;
					}

					.unit{
						color: #555;
						font-size: 12px;
					}

					.brand{
						font-size: 14px;
					}
					.addons_container{
						.addons_title{
							font-weight: 600;
							color: #3dbcf6;
							font-size: 14px;
						}
						span{
							color:black;
							font-size: 12px;
						}

					}

					.price{
						font-size: 18px;
						font-weight: 600;
					}
				}

				.actions_container{
					position: relative;
					display: grid;
					justify-items:center;
					padding: 20px 5px;
					box-sizing:border-box;
					width: 100%;
					// background-color: violet;

					.qty_container{
						position: relative;
						align-self: start;
						border:1px solid lightgrey;
						border-radius: 5px;


						button{
							float: left;
							width:35px;
							height: 35px;
							outline: none;
							border:none;
							color: grey;
							font-weight:500;
							background-color: transparent;
						}

						.product_qty{
							float: left;
							width:45px;
							height: 35px;
							border-width: 0px 1px 0px 1px;
							border-color: lightgrey;
							border-style: solid;
							// outline: none;
							text-align: center;
							outline: none;
						}

					}

					.product_remove_button{
						align-self:end;
						color: #b93a3a;
						cursor: pointer;
					}
				}

				@media (max-width:680px) {
					grid-template-columns:100%;
					.product_image{
						height: 120px;
					}
					.product_details{
						text-align: center;
					}

					.actions_container{
						grid-template-columns:auto auto;
						padding: 5px;
						.qty_container, .product_remove_button{
							align-self: center;
						}
					}

				}

				&:last-child{
					border:none;
				}

			}
		}
	}
	.billing_details_block{
		position: relative;
		display: grid;
		align-content:start;
		// grid-template
		width:100%;
		padding: 10px;
		box-sizing:border-box;
		// background-color: yellow;

		.cart_details_block{
			position: relative;
			display: grid;
			align-content:start;
			// grid-template
			width:100%;
			padding: 10px;
			box-sizing:border-box;
			background-color: white;
			box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
			border-radius: .375rem;

			.bill_title{
				font-size: 20px;
				font-weight: 500;
				color: #333;
				letter-spacing: 1px;
			}

			.amount_grid{
				position: relative;
				display: grid;
				grid-template-columns:auto auto;
				color: grey;

				.total_amount_label{
					justify-self:start;
					font-weight: 14px;
				}

				.total_amount{
					font-weight: 500;
					font-size: 20px;
					justify-self:end;
					color : #666;
				}

			}

			.label_input_container{
				display: grid;

				label{
					color: grey;

					.required{
						color: #d21616;
					}
				}

				input{
					border:1px solid #00c3ff;
					height: 35px;
					border-radius: .25rem;
					color: grey;
					padding-left: 5px;
					&:focus{
						border-color:red;
					}

				}
				textarea{
					border:1px solid #00c3ff;
					// height: 35px;
					border-radius: .20rem;
					color: grey;
					background-color: transparent;
					&:focus{
						outline: none;
						border: 1px solid red;
					}
				}
			}

			.delivery_type_container{
				font-size: 14px;
				margin:10px 5px;
				color: grey;
			}

			.delivery_instruction{
				font-size: 12px;
				color: #161c3b;

			}

			.captcha_policy_container{
				font-size: 11px;
				color: grey;
				font-family: "Lato",sans-serif;

				a{
					color: blue;
					text-decoration: underline;
				}
			}

			.place_order_button{
				// margin-top: 20px;
				background-image: linear-gradient(to bottom right, #2af2d6, #2a76f2);
				// background-color: #2a76f2;
				font-weight: 500;
				border-radius: .25rem;
				-webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2),0 2px 10px 0 rgba(0,0,0,0.05);
				box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2),0 2px 10px 0 rgba(0,0,0,0.05);
				font-size: 12px;
				padding: 17px 29px 16px 29px;
				line-height: 1;
				color: white;
				transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

				&:hover{
					box-shadow: 0 5px 11px 0 rgba(0,0,0,0.14),0 4px 15px 0 rgba(0,0,0,0.12);
				}
			}

			>*{
				margin: 5px;
			}
		}
	}
	@media (max-width:900px) {
			grid-template-columns:100%;
	}

}