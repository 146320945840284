button,
input,
a {
    display: inline-block;
    outline: none;
    border: 0px;

    background-color: transparent;

    margin: 0;
    padding: 0;

    text-decoration: none;
    color: inherit;
}

a:hover {
    color: inherit;
}

* {
    scrollbar-width: thin;
}

button::-moz-focus-inner,
a::-moz-focus-inner {
    border: 0;
}


/* width */
::-webkit-scrollbar {
    width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


//::-webkit-scrollbar {
//    width: 0px;
//    /* remove scrollbar space */
//    background: transparent;
//    /* optional: just make scrollbar invisible */
//}
