.footer{
	position: relative;
	display: grid;
	grid-template-columns:50% 50%;
	width: 100%;
	background-color: black;
	// overflow: hidden;
	font-family: "Lato",sans-serif;
	background-color: #333;
	color: #a8a8a8;
	box-shadow: 0 0 10px grey;

	.dealer_details{
		position: relative;
		padding:20px 10px;
		text-align: center;
		width: 100%;
		// background-color: blue;
		.dealer_name{
			font-size: 16px;
			font-weight: 500;
		}

		.address{
			font-size: 14px;
		}

		.email_id{
			position: relative;
			display: block;
		}
	}

	.social_media_container{
		position: relative;
		display: grid;
		width: 100%;
		padding:10px;
		justify-items:center;
		align-content:center;
		// background-color: green;

		.find_us{
			position: relative;
			font-size: 18px;
			font-weight: 500px;
			font-family: "Lato",sans-serif;
		}

		.social_media_icons{
			position: relative;
			display: grid;
			grid-template-columns:auto auto auto auto;
			align-items: center;
			justify-content:center;
			a{
				float: left;
				margin: 5px 10px;
			}
		}
	}
	@media(max-width:720px){
		grid-template-columns:100%;
		.dealer_details{
			padding-bottom: 0px;
		}
		.social_media_container{
			padding: 0px 10px 10px 10px; 
			.find_us{
				display: none;
			}
		}
	}
}