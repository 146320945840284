
.header{
	position: sticky;
	top:0%;
	z-index: 2020;
	display: grid;
	grid-template-columns:max-content auto max-content max-content max-content;
	grid-template-areas: "logo dealer spacer select cart";
	width: 100%;
	height: auto;
	// background-color: black;
	// overflow: hidden;
	align-items:center;
	font-family: "Lato",sans-serif;
	// justify-items:center;
	background-color: #161c3b;
	// background-image: linear-gradient(to bottom, white, lightgrey);
	box-shadow: 0 0 10px grey;

	.company_logo{
		grid-area:logo;
		position: relative;
		height: 60px;
		margin:4px;
	}
	
	.company_name{
		grid-area:logo;
		display: none;
		// width: 300px;
		height: 100%;
		// background-color: yellow;
	}

	.dealer{
		grid-area:dealer;
		justify-self:center;
		color: white;
		font-weight: 500;
		font-size: 18px;
		letter-spacing: 2px;

		// background-color: red; 
	}

	.spacer{
		grid-area:spacer;
	}

	.category_select_container{
		grid-area:select;
		justify-self:center;
		width: 500px;
		margin:7px 0px;
		// background-color: red;

		.category_select{
			border:1px solid #00c3ff;
			border-radius: 6px; 
			*{
				border:none;
			}
			.pholder,.css-tj5bde-Svg{
				color:grey;
			}
			.css-1okebmr-indicatorSeparator{
				background-color: grey;
			}
		}
	}

	.cart{
		position: relative;
		grid-area:cart;
		justify-self:right;
		font-size: 42px;
		color: orangered;
		margin: -3px 15px 0px 30px;

		.cart_count{
			color: white;
			font-family: "Lato",sans-serif;
			font-weight: 500;
			padding-top: 10px;
			font-size: 20px;
		}
	}
	@media only screen and (max-width: 900px){
		grid-template-columns:max-content auto max-content;
		grid-template-areas: "logo spacer cart" 
							"select select select"
							"dealer dealer dealer";

		.company_logo{
			height: 50px;
		}
		.dealer{
			padding: 0px 10px 5px 10px;
			font-size: 16px;
		}
		.category_select_container{
			width:95%;
			margin-top:0px;
		}

		.cart{
			font-size: 36px;
			margin: 0px 10px 0px 30px;
			.cart_count{
				font-size: 16px;
			}
		}


	}
}