.products_block{
    min-height: 80vh;
    text-align: center;

    .image_slider_container_web{
        display: block;
    }
    .image_slider_container_mob{
        display: none;
    }
    .title_container{
        display: grid;
        grid-template-columns: auto max-content auto;
        margin: 10px auto;
        width: 800px;
        max-width: 95%;
        align-items: center;
        .line{
            height: 2px;
            width: 100%;
            border-radius: 50px;
            background-color: #333;
        }
        .title{
            padding-left:20px;
            padding-right:20px;
            max-width: 60vw;
            text-align: center;
            font-family: sans-serif;
            font-size: 26px;
            letter-spacing: 3px;
            color: #333;
        }
    }
        

    .search_container{
        position: relative;
        display: grid;
        width:100%;
        margin:1% 0px;
        padding: 0px 3%; 
        box-sizing:border-box;
        grid-template-columns:30% 70%;
        // align-items:center;

        .search_input{
            position: relative;
            width:100%;
            padding: 5px;
            border:1px solid lightgrey;
            border-radius: 5px;
            background-color: white;
        }

        .show_results_label{
            position: relative;
            text-align: right;
            width:100%;
            color: #555;

        }
    }

    .show_more_button{
        position:relative;
        display: none;
        text-align: center;
        border-radius: 8px;
        border: 1px solid;
        padding: 7px 12px 5px 12px;
        // box-sizing:border-box;
        color: #0092e1 ;
        border-color: #0092e1;
        margin: 10px auto;
        font-size:  18px;
        cursor: pointer;
        font-family: Helvetica,arial,sans-serif;
        &:hover{
            color: #797777;
            border-color:#797777;
        }
    }

    .loading_gif{
        display: none;
        height: 50px;
    }

    .no_more_results{
        position: relative;
        // display: none;
        color: grey;
        padding: 10px 0px;
        width:90%;
        margin:5px auto 20px auto;
        border-top: 2px dashed grey;
        border-bottom: 2px dashed grey;
    }
     @media only screen and (max-width: 800px) {
        .image_slider_container_web{
            display: none;
        }
        .image_slider_container_mob{
            display: block;
        }
        .search_container{
            grid-template-columns:50% 50%;
        }

     }

    @media only screen and (max-width: 500px) {
        
        .title_container {
            margin: 5px auto;
            .title {
                font-size: 14px;
                padding: 0px 10px;
            }
        }

        .search_container{
            grid-template-columns:100%;
            font-size: 12px;
            margin: 0px;

            .search_input{
                // padding: 4px;
                // box-sizing:border-box;
            }

            .show_results_label{
                text-align: left;
            }

        }

        .show_more_button{
            border-radius: 5px;
            padding: 5px 9px;
            font-size: 16px;
        }

        .no_more_results{
            width:95%;
            font-size: 12px;
            padding: 7px 0px;
            margin:5px auto 5px auto;
        }
    }

}
