/*//BEGIN Themes*/
$c1-light : blue;
$c1 : grey;
$c2 : black;
.title_theme1 {
    width: 100%;
    height: 60px;
    font-family: sans-serif;
    //        background-color: #2c2cff;
    background-color: $c1;
    color: white;
    font-size: 19px;
    margin-bottom: 20px;
    pointer-events: none;
}

.title_theme2 {
    width: 100%;
    height: 50px;
    font-family: sans-serif;
    background-color: #efeeee;
    border-bottom: 2px solid $c1;
    color: black;
    font-size: 19px;
    margin-bottom: 25px;
    padding-left: 15px;
    pointer-events: none;

}

.title_theme3 {
    margin-top: 20px;
    margin-bottom: 25px;
    width: 100%;
    height: 50px;
    font-family: sans-serif;
    background-color: #efeeee;
    color: black;
    font-size: 19px;

    pointer-events: none;
}

.button_theme1 {
    background-color: $c1;
    color: white;
    border: 1px solid $c1;
    cursor: pointer;
    transition: 0.3s all;
    font-size: 18px;

    &:hover {
        color: $c1;
        background-color: white;
    }
}

.button_theme2 {
    color: $c1;
    background-color: transparent;
    border: 1px solid $c1;
    cursor: pointer;
    transition: 0.3s all;
    font-size: 18px;

    &:hover {
        background-color: $c1;
        color: white;
    }
}
.button_theme3 {
    border-radius: 999px;
    background-color: $c2;
    border: 1px solid $c2;
    color: white;
    font-family: sans-serif;
    cursor: pointer;
    max-width: 100%;
    transition: 0.3s all;

    &:hover {
        border-color: $c1-light;
        background-color: white;
        color: black;
    }
}

.input_container_theme1 {
    position: relative;
    width: auto;
    height: auto;
    min-height: 53px;
    max-width: 100%;
    margin: 0px;
    color: black;
    border: 1px solid #b0b0b0;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 1px;
    //    box-sizing: border-box;

    //            padding: 1px;//CSS bug fix, border of parent disappearing at certain screen widths.
    &:hover {
        border: 1px solid black;

        .input_label {
            color: black;
        }
    }

    &:focus-within {
        padding: 0px;
        border: 2px solid $c1;

        .input_label {
            color: $c1;
        }
    }

    input {
        border-radius: 3px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        max-width: 100%;
        height: 53px;
        padding-left: 30px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;

    }

    input[type="file"] {
        max-width: 100%;
        height: 53px;
        padding-left: 5px;
        padding-top: 10px;
        font-size: 18px;
        box-sizing: border-box;
    }

    input[type="date"] {
        max-width: 100%;
        height: 53px;
        padding-left: 10px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;

    }

    input[type="radio"] {
        transform: scale(1.5);
        margin: 0px 5px;
        cursor: pointer;
    }

    button {
        height: 100%;
    }

    select {
        width: 309px;
        max-width: 95%;
        height: 37px;
        margin-top: 8px;
        margin-left: 2.5%;
        padding-left: 20px;
//        padding-top: 5px;
        font-size: 18px;
        border: none;
        background-color: white;
        box-sizing: border-box;

    }

    textarea {
        resize: none;
        width: 350px;
        max-width: 100%;
        height: 100px;
        border: none;
        margin: 0px;
        padding: 0px;
        padding-left: 30px;
        padding-top: 20px;
        box-sizing: border-box;
        font-size: 18px;
        text-decoration: none;

    }

    .input_label {
        position: absolute;
        top: 0%;
        left: 0%;
        padding-left: 6px;
        padding-right: 5px;
        margin-left: 10px;
        transform: translate(0%, -50%);
        font-family: sans-serif;
        color: grey;
        font-size: 14px;
        letter-spacing: 2px;
        background-color: white;
        cursor: default;

    }
}

.input_container_theme2 {
    position: relative;
    display: inline-block;
    width: auto;
    position: relative;
    //        border: 1px solid lightgrey;
    //        border: 1px solid grey;
    height: auto;
    min-height: 53px;
    max-width: 100%;
    margin: 0px;
    color: black;
    border-bottom: 2px solid #b0b0b0;
    border-radius: 3px;
    margin-bottom: 20px;
    box-sizing: border-box;


    * {
        letter-spacing: 3px;
        font-weight: 500;
    }

    //            padding: 1px;//CSS bug fix, border of parent disappearing at certain screen widths.
    &:hover {
        border-bottom: 2px solid black;
    }

    &:focus-within {
        border-bottom: 3px solid black;
    }

    input {
        border-radius: 3px;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        max-width: 100%;
        height: 53px;
        padding-left: 30px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;

    }

    input[type="date"] {
        max-width: 100%;
        height: 53px;
        padding-left: 10px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;

    }

    input[type="radio"] {
        transform: scale(1.5);
        margin: 0px 5px;
        cursor: pointer;
    }

    button {
        //        text-align: left;
        min-height: 53px;
    }

    textarea {
        resize: none;
        width: 350px;
        max-width: 100%;
        height: 100px;
        border: none;
        margin: 0px;
        padding: 0px;
        padding-left: 30px;
        padding-top: 20px;
        box-sizing: border-box;
        font-size: 18px;
        text-decoration: none;

    }

    .icon {
        position: absolute;
        top: 50%;
        left: 0%;
        width: 30px;
        text-align: center;
        //        height: 20px;
        //        padding-left: 6px;
        //        padding-right: 5px;
        //        margin-left: 9px;
        transform: translate(0%, -50%);
        //        font-family: icons;
        color: $c1-light;
        font-size: 18px;
        //        letter-spacing: 2px;
        //        background-color: white;
        cursor: default;
    }
}

.input_container_theme3 {
    $label_width: 30px;
    position: relative;
    display: inline-block;
    width: auto;
    position: relative;
    //        border: 1px solid lightgrey;
    //        border: 1px solid grey;
    height: auto;
    min-height: 53px;
    max-width: 100%;
    margin: 0px;
    color: black;
    border: 2px solid $c1;
    border-radius: 3px;
    box-sizing: border-box;
    //            padding: 1px;//CSS bug fix, border of parent disappearing at certain screen widths.
    //    &:hover {
    //        border: 1px solid black;
    //    }
    transition: all 0.2s;

    &:focus-within {
        //        border: 2px solid $c1;

        //        .input_label {
        //            color: $c1;
        //        }
        transform: scale(1.1);
        box-shadow: 0 0 10px $c1;
    }

    input {
        border-radius: 3px;
    }

    input[type="text"],
    input[type="email"] {
        max-width: 100%;
        height: 53px;
        padding-left: 5px;
        padding-right: $label_width+5px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;
        text-align: center;

    }

    input[type="date"] {
        max-width: 100%;
        height: 53px;
        padding-left: 10px;
        //            padding-top: 5px;
        font-size: 18px;
        box-sizing: border-box;

    }

    input[type="radio"] {
        transform: scale(1.5);
        margin: 0px 5px;
        cursor: pointer;
    }

    button {
        //        text-align: left;
        min-height: 53px;
    }

    textarea {
        resize: none;
        width: 350px;
        max-width: 100%;
        height: 100px;
        border: none;
        margin: 0px;
        padding: 0px;
        padding-left: 5px;
        padding-right: $label_width+5px;
        padding-top: 20px;
        box-sizing: border-box;
        font-size: 18px;
        text-decoration: none;

    }

    .input_label {
        position: absolute;
        top: 0%;
        right: 0%;
        height: 100%;
        width: $label_width;
        padding-left: 2px;
        box-sizing: border-box;
        font-family: sans-serif;
        //        color: $c1;
        color: white;
        font-weight: 600;
        font-size: 15px;
        //        letter-spacing: 2px;
        background-color: $c1;
//        background-color: white;
    }
}


.message_container {
    display: none;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding-top: 200px;
    text-align: center;
    //    border: 5px solid black;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;

    .message_title {
        font-family: sans-serif;
        font-size: 27px;
        font-weight: 500;
        margin-top: 40px;
    }

    .message_description {
        font-family: sans-serif;
        font-size: 18px;
        color: dimgrey;
        margin-top: 40px;
        //        font-weight: 300;
        //        margin:40px 0px;
    }

    .action_button {
        font-family: sans-serif;
        font-size: 18px;
        margin-top: 40px;
        background-color: #333;
        color: white;

    }
}

//**END Themes
