body{
	overflow-y: scroll;
    overflow-x:none;
    background-color: #f8f8f8;
}

input[type=number]{
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.pages{
    min-height: 80vh;
	padding-top:10px;
	box-sizing: border-box;

    @media(max-width:600px){
        padding-top: 5px;
    }
}
