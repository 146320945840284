.lctc {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
}

.tc {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.lc {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    margin-right: -50%;
}
.fill{
    width: 100%;
    height: 100%;
}
