$nav_height:50px;
.bottom_nav_placeholder{
		position: relative;
		height: $nav_height;
		display: none;
		width: 100%;
		background-color: transparent;	
	
	.bottom_nav{
		position: fixed;
		bottom: 0%;
		left: 0%;
		display: grid;
		grid-template-columns:auto max-content auto;
		height: $nav_height;
		width: 100%;
		background-color: white;
		box-shadow: 0 5px 13px 0 black;
		.grid_item{
			/*border: 1px solid red;*/
			display: grid;
			justify-content: center;
			align-items: center;
			color: white;

			i{
				font-size: 21px;
				color: #1b32ac;
			}
		
		}
		
		.seperator{
			align-self:center;
			height: 51%;
			width: 3px;
			background-color: #1b32ac;
			border-radius:50px;
		}
	}
	@media only screen and (max-width: 900px){
		display: block;
	}
}